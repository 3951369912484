// Timeout, want bij Mens bijvoorbeeld doet JS iets met de body...
setTimeout(function () {
    initCookiebox();
}, 100);

function initCookiebox() {
    var body = document.querySelector('body'),
        cookiebox = document.querySelector('#cookiebox'),
        btn_akkoord = document.querySelector('#cookiebox .cookies .btn-akkoord'),
        btn_settings = document.querySelector('#cookiebox .cookies .btn-settings'),
        btn_herzien = document.querySelector('#cookies-herzien'),
        cookie = getCookie('cookie_level');

    if (body.contains(cookiebox)) {
        document.querySelector('body').style.overflow = 'hidden';

        btn_akkoord.addEventListener('click', akkoord);
        btn_settings.addEventListener('click', settings);

        if (cookie == 4) {
            btn_settings.click();
        }
    }

    if (body.contains(btn_herzien)) {
        btn_herzien.addEventListener('click', herzien);
    }
}

function akkoord() {
    setCookie('cookie_level', 3, 365);

    document.querySelector('#cookiebox').style.opacity = 0;

    setTimeout(function () {
        location.reload();
    }, 300);
}
function settings() {
    document.querySelector('#cookiebox .cookies').style.opacity = 0;
    setTimeout(function () {
        document.querySelector('#cookiebox .cookies').style.zIndex = -1;

        setTimeout(function () {
            document.querySelector('#cookiebox .settings').style.opacity = 1;
        }, 300);
    }, 300);

    var btn_save_settings = document.querySelector('#cookiebox .settings .btn-settings');

    btn_save_settings.addEventListener('click', setLevel);
}

function setLevel() {
    var options = document.querySelectorAll('#cookiebox .settings input');

    for (var i = 0; i < options.length; i++) {
        if (options[i].checked) {
            setCookie('cookie_level', options[i].getAttribute('value'), 365);

            document.querySelector('#cookiebox').style.opacity = 0;

            setTimeout(function () {
                location.reload();
            }, 300);
        }
    }
}

function herzien(e) {
    e.preventDefault();
    setCookie('cookie_level', 4, 365);

    location.reload();
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

function removeCookie(name) {
    if (get_cookie(name)) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}
